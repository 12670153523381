/* ----------------Color Mode */

.colorModeContainer{
    display: block;
    width: 100%;
}

.colorMode{
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    max-width: 2rem;
    max-height: 2rem;
    margin-top: 1rem;
    margin-right: 1rem;
}


/* ----------------Intro */


@import url('https://fonts.googleapis.com/css2?family=Anton&family=Archivo+Black&family=Merriweather+Sans:ital,wght@1,700&family=Paytone+One&display=swap');


.intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.name{
    display: flex;
    font-family: Archivo Black;
    font-size: 130px;
}

.letterSpace{
    margin-left: .25rem;
    margin-right: .25rem;
}

.c{
    color: #014f86;
}
.r{
    color: #2a6f97;
}
.a{
    color: #2c7da0
}
.i{
    color: #468faf;
}
.g{
    color: #61a5c2;
}
.l{
    color: #014f86;
}
.e{
    color: #2a6f97;
}
.s{
    color: #2c7da0
}
.l{
    color: #468faf;
}
.iTwo{
    color: #61a5c2;
}
.eTwo{
    color: #89c2d9;
}

.title{
    font-family: Archivo Black;
    font-size: 40px;
}

.location{
    font-family: Archivo Black;
    font-size: 20px;
}

@media screen and (max-width: 900px){
    .name{
        font-size: 100px;
    }
}

@media screen and (max-width: 740px){
    .name{
        font-size: 80px;
    }
    .title{
        font-size: 25px;
    }
}

@media screen and (max-width: 600px){
    .name{
        font-size: 60px;
    }
    .title{
        font-size: 20px;
    }
    .location{
        font-size: 20px;
    }
}

@media screen and (max-width: 450px){
    .name{
        font-size: 50px;
    }
    .title{
        font-size: 20px;
    }
}

@media screen and (max-width: 389px){
    .name{
        font-size: 40px;
    }
    .title{
        font-size: 16px;
    }
}

/* ----------------Projects */

.vidContainer{
    display: flex;
    overflow: hidden;
    width: 100%;
}

.ulStyle{
    list-style: none;
    gap: .25rem;
}

.gridFlex{
    display: flex;
    margin-bottom: .5rem;
    margin-top: .5rem;
    gap: .5rem;
}

.responsive-iframe{
    display: flex;
    justify-content: center;
    align-items: center;
}

.recentWork{
    font-family: Andale Mono;
    font-size: 70px;
    margin-left: 3rem;
    margin-top: 7rem;
    margin-bottom: 2rem;
    margin-right: 3rem;
}

.projects{
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
    max-width: 100vw;
}

.singleProject{
    display: flex;
    margin-left: 3rem;
    margin-right: 3rem;
    padding-bottom: 5rem;
    padding-top: 5rem;
    border-bottom: 1px solid;
    gap: 4rem;
}

.projectInfo{
    display: flex;
    flex-direction: column;
    min-width: 50%;
    max-width: 50%;
}

.projectInfo h1{
    font-family: Andale Mono, roc-grotesk-wide, sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 40px;
    margin-top: 0px;
}

.projectAboutInfo{
    font-family: Andale Mono, aktiv-grotesk, sans-serif;
    font-weight: 300;
    font-size: 18px;
    padding-top: 1rem;
}

.ellipsis{
    font-size: 40px;
}

.projectDeployInfo{
    font-family: Andale Mono, aktiv-grotesk, sans-serif;
    font-weight: 300;
    font-size: 18px;
    padding-top: 1rem;
}

.projectPicture{
    max-width: 50%;
    max-height: 50%;
    display: block;

}

img{
    max-width: 100%;
}

.deployBtn{
    padding-top: 1rem;
}

.socials{
    font-size: 50px;
}

a{
    text-decoration: none;
    color: black;
}


@media screen and (max-width: 970px){
    .singleProject{
        flex-direction: column;
        gap: 0.5rem;
    }
    .projectPicture{
        max-width: 100%;
    }
    .projectInfo{
        padding-top: 2rem;
        max-width: 100%;;
    }
    .projectInfo h1{
        padding-left: 1rem;
    }
    .projectAboutInfo{
        padding-left: 1rem;
    }
    .projectDeployInfo{
        padding-left: 1rem;
    }
    .socials{
        padding-left: 1rem;
    }
    .deployBtn{
        padding-left: 1rem;
    }
    .ellipsis{
        padding-left: 1rem;
    }
}

@media screen and (max-width: 900px){
    .recentWork{
        font-size: 50px;
    }
}

/* ----------------About */

#aboutMeTitle{
    padding-left: 1rem;
}

.aboutMeInfo{
    padding-left: 1rem;
}

#aboutEllipsis{
    padding-left: 1rem;
}

#aboutConnect{
    padding-left: 1rem;
}

.iconMarginBottom{
    margin-bottom: 2rem;
    padding-left: 1rem;
    margin-top: 1rem;
}

.socialsAbout{
    margin-right: 2rem;
}

#collabHeader{
    padding-left: 1rem;
}

#collabMessage{
    padding-left: 1rem;
}

#collabEmail{
    padding-left: 1rem;
    font-size: 25px;
    color: #61a5c2;
    padding-bottom: 1rem;
}

@media screen and (max-width: 970px){
    .aboutMeInfo h1{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .aboutMeInfo{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* ----------------Footer */

.portfolioFooter{
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    font-family: Andale Mono, aktiv-grotesk, sans-serif;
    font-weight: 300;
    line-height: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.footerEmail{
    color: #61a5c2;
}

.sameLine{
    display: flex;
    flex-direction: row;
}

.spaceHeart{
    margin-left: .5rem;
    margin-right: .5rem;
    padding-top: .5rem;
    color: red;
}